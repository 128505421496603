import React from 'react';

export const faqsData = [
  {
    id: 1,
    title: "My order is getting rejected/canceled, what should I do?",
    desc: <p>Please provide proper information in all the mandatory fills in the form. Most of the time we reject the order because of insufficient information. Please follow the below mentioned step to avoid order rejection/cancellation 
      <ol>
        <li> 1.Make sure you are providing an accurate mockup. We prefer you to provide the mockup you have shown to your customer</li>
        <li>2.Provide proper delivery address, Courier address does not need house no, while Pathao and other home delivery services need to have house no.</li>
        <li>3.Always provide a 300 dpi or high resolution image with the right background setting. Your customer doesn’t want a pixelated product.</li>
      </ol>
    </p>,
  },
  // Add more FAQs below as needed
  {
    id: 2,
    title: "How can I increase my sales?",
    desc: <p>Increasing sales online is tricky when you are just starting out so here are 5 tips that you can apply to boost your sales without running any ads.
      <ol>
        <li>1.Learn what your community wants, the most important part of your online business is listening to your crowd and exist in a group who loves your design and art.</li>
        <li>2.Create design for specific events, Aim for special color and theme for that occasion.</li>
        <li>3.You can target a specific group of people with specific interest. Example: Selling famous quotes or poems printed on a T-shirt. Make sure to add your touch and make it copyright free.</li>
        <li>4.FOMO (Fear Of Missing Out). Be that artist who is always inspired to make some new art or design. Make your design exclusive and offer it for a limited time, people who follow you will collect it & they will stay attentive to your Drops.</li>
        <li>5.Offer Discounts, Encourage your customer to buy 2 products instead of one so that they can avail a free delivery. Or you can offer discounts for Couples, Sports team, Friends, Crew.</li>
      </ol>
    </p>,
  },
  
  {
    id: 3,
    title: "My customer got wrong/defective product",
    desc: <p>We always try our best to ensure a smooth environment for Print on demand sellers. If your customer got the wrong order, please write your message and add a picture of the problem in Contact us</p>,
  },
];
