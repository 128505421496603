export const faqsData = [
    {
      id: 1,
      title: "Do I need to order in bulk?",
      desc: " No, you just need to set up your shop online and start taking orders. All you need is to build up your own community who loves your work. Or you can sell like an E-commerce tycoon , it's your call.",
    },
    {
      id: 2,
      title: "Can you deliver my single order to the customer?",
      desc: "Yes, we take orders of any amount and deliver straight to your customer.",
    },
    {
      id: 3,
      title: "How long does it take to complete an order?",
      desc: "We usually take 3 to 5 days to complete an order from the day of order submission.",
    },
    {
      id: 4,
      title: "Do I need to pay for registration?",
      desc: "Registration fee is 1500 BDT",
    },
   
  ];
  